<template>
  <div class="content min_box" v-wechat-title="title + '列表'">
    <nav-bar :title="title" />
    <!-- 最新动态 -->
    <div v-if="type == 1">
      <van-list
        v-if="navList.length > 0"
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <van-tabs
          v-model="navIndex"
          color="#35a3bd"
          @change="onChange"
          swipeable
          animated
        >
          <van-tab v-for="(item, index) in navList" :title="item" :key="index">
            <ul>
              <li
                @click="newsDetail(items)"
                v-for="items in list[index].list"
                :key="items.id"
              >
                <span>●</span>
                {{ items.news_name }}
              </li>
            </ul>
          </van-tab>
        </van-tabs>
      </van-list>
    </div>
    <!-- 投资项目 -->
    <div class="" v-else-if="type == 2">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <van-tabs color="#35a3bd" @change="onChange" swipeable animated>
          <van-tab v-for="(item, index) in navList" :title="item" :key="index">
            <ul>
              <li
                @click="projectDetail(items)"
                v-for="items in list[index].list"
                :key="items.id"
              >
                <span>●</span>
                {{ items.name }}
              </li>
            </ul>
          </van-tab>
        </van-tabs>
      </van-list>
    </div>
    <!-- 招商优势 -->
    <div class="" v-else-if="type == 3">
      <!-- <div class="youshi"> -->
      <!-- <div class="box">
          <div
            @click="toFour(item)"
            v-for="item in articleTourList"
            :key="item.id"
            class="list"
          >
            <van-image class="imgs" fit="cover" :src="item.impression_img" />
            <span class="txt" v-if="item.impression_cate == 3">交通优势</span>
            <span class="txt" v-else-if="item.impression_cate == 4"
              >人力优势</span
            >
            <span class="txt" v-else-if="item.impression_cate == 5"
              >资源优势</span
            >
            <span class="txt" v-else-if="item.impression_cate == 6"
              >成本优势</span
            >
          </div>
        </div> -->
      <van-tabs
        color="#35a3bd"
        v-model="navIndex"
        @change="youshiChange"
        swipeable
        animated
      >
        <van-tab
          v-for="(item, index) in articleTourList"
          :title="item.name"
          :key="index"
        >
          <div class="detail" v-html="item.detail"></div>
        </van-tab>
      </van-tabs>
      <!-- </div> -->
    </div>
    <!-- 宣传手册 -->
    <div class="fm" v-else-if="type == 4">
      <pic-list
        :list="videoList"
        @toDetail="tobook"
        picFlag
        :picPositionFlag="false"
      ></pic-list>
    </div>
    <!-- 魅力风貌 -->
    <div class="" v-else-if="type == 5">
      <pic-list
        :list="videoList"
        @toDetail="topic"
        picFlag
        :picPositionFlag="false"
      ></pic-list>
    </div>
    <!-- 招商政策 -->
    <div class="" v-else-if="type == 6">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <van-tabs color="#35a3bd" @change="onChange" swipeable animated>
          <van-tab
            v-for="(item, index) in navList"
            :title="item.ser_name"
            :key="item.id"
          >
            <ul>
              <li
                @click="topolicyDetail(items)"
                v-for="items in list[index].list"
                :key="items.id"
              >
                <span>●</span>
                {{ items.news_name }}
              </li>
            </ul>
          </van-tab>
        </van-tabs>
      </van-list>
    </div>
  </div>
</template>

<script>
import navBar from "@/components/nav/nav";
import picList from "@/components/picList/picList";
import { Tab, Tabs, List, Image as VanImage } from "vant";
import {
  impression_detail,
  indexebook,
  indexfeature,
  newsheader,
  newsLists,
  project_list,
  servervice,
  server_cates_one,
} from "@/api/http";
export default {
  name: "More",
  data() {
    return {
      type: 1,
      navList: [],
      navIndex: 0,
      list: [],
      articleTourList: [],
      videoList: [],
      loading: false,
      finished: false,
      title: "更多页",
      region: null,
      url: [
        "gardenlist",
        "landlist",
        "workslist",
        "itemslist",
        "buildlist",
        "masslist",
        "more_enterprise",
        "more_business",
      ],
    };
  },

  components: {
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [List.name]: List,
    [VanImage.name]: VanImage,
    navBar,
    picList,
  },

  created() {
    this.region = JSON.parse(localStorage.getItem("region_name"));
    // 1是投资动态 2是投资项目 3是招商优势 4是手册 5是风貌  6是投资政策
    this.type = this.$route.params.type;
    if (this.type == 1) {
      this.title = "投资动态";
      newsheader().then((res) => {
        this.navList = res.data.region_new;
        this.navList.forEach((v) => {
          this.list.push({
            page: 1,
            flag: false,
            list: [],
          });
        });
      });
    } else if (this.type == 2) {
      this.navList = [
        "园区",
        "地块",
        "厂房",
        "项目",
        "楼宇",
        "众创",
        "名企",
        "商协会",
      ];
      this.title = "投资项目";
      this.navList.forEach((v) => {
        this.list.push({
          page: 1,
          flag: false,
          list: [],
        });
      });
    } else if (this.type == 3) {
      this.title = "招商优势";
      impression_detail({
        region_code: this.$route.params.code,
        type: 1,
      }).then((res) => {
        let arr = res.data.impression_detail;
        let title = "";
        arr.forEach((v) => {
          if (v.impression_cate != 2) {
            if (v.impression_cate == 3) {
              title = "交通优势";
            } else if (v.impression_cate == 4) {
              title = "人力优势";
            } else if (v.impression_cate == 5) {
              title = "资源优势";
            } else if (v.impression_cate == 6) {
              title = "成本优势";
            }
            this.articleTourList.push({
              id: v.id,
              name: title,
              detail: "",
              cate: v.impression_cate,
            });
          }
        });
        this.articleTourListLoding();
      });
    } else if (this.type == 4) {
      this.title = "宣传手册";
      indexebook({
        region_code: this.$route.params.code,
      }).then((res) => {
        res.data.indexebooks.forEach((v) => {
          this.videoList.push({
            id: v.id,
            name: v.ebook_name,
            url: v.ebook_img,
            e_url: v.ebook_url,
          });
        });
      });
    } else if (this.type == 5) {
      this.title = this.region.re_name ? "魅力" + this.region.re_name : "风貌";
      indexfeature({
        region_code: this.$route.params.code,
      }).then((res) => {
        res.data.indexfeatures.forEach((v) => {
          if (v.features_cate != 2) return;
          this.videoList.push({
            id: v.id,
            name: v.features_name,
            url: v.features_url,
          });
        });
      });
    } else if (this.type == 6) {
      this.title = "招商政策";
    }
  },
  methods: {
    youshiChange(index, title) {
      this.navIndex = index;
      if (this.articleTourList[this.navIndex].detail) {
        return;
      }
      this.articleTourListLoding(this.navIndex);
    },
    articleTourListLoding() {
      impression_detail({
        region_code: this.$route.params.code,
        impression_cate: this.articleTourList[this.navIndex].cate,
        type: 0,
      }).then((res) => {
        this.articleTourList[this.navIndex].detail = res.data.impression_detail.impression_description
      });
    },
    onChange(index, title) {
      this.navIndex = index;
      if (this.list[this.navIndex].flag) {
        return;
      }
      this.finished = false;
      this.loading = true;
      this.onLoad();
    },
    //招商政策列表
    touFLList(e) {
      server_cates_one({
        id: e,
        ser_type: 1,
        page: this.list[this.navIndex].page,
      }).then((res) => {
        let data = res.data.server_data.data;
        if (data.length > 0) {
          data.forEach((v) => {
            this.list[this.navIndex].list.push(v);
          });
        }
        this.list[this.navIndex].flag = true;
        // 数据全部加载完成
        if (data.length == 0) {
          this.finished = true;
        }
        // 加载状态结束
        this.loading = false;
        this.list[this.navIndex].page++;
      });
    },
    //投资项目列表接口
    prokectList() {
      project_list(
        {
          region_code: this.$route.params.code,
          page: this.list[this.navIndex].page,
        },
        this.url[this.navIndex]
      ).then((res) => {
        var data = [];
        var e = this.navIndex;
        if (e == 0) {
          data = res.data.gardens.data;
          if (data.length > 0) {
            data.forEach((v, index) => {
              this.list[e].list.push({
                id: v.id,
                name: v.garden_name,
                Latitude: v.Latitude,
                Longitude: v.Longitude,
              });
            });
          }
        } else if (e == 1) {
          data = res.data.land.data;
          if (data.length > 0) {
            data.forEach((v, index) => {
              this.list[e].list.push({
                id: v.id,
                name: v.land_name,
                Latitude: v.Latitude,
                Longitude: v.Longitude,
              });
            });
          }
        } else if (e == 2) {
          data = res.data.workslist.data;
          if (data.length > 0) {
            data.forEach((v, index) => {
              this.list[e].list.push({
                id: v.id,
                name: v.works_name,
                Latitude: v.Latitude,
                Longitude: v.Longitude,
              });
            });
          }
        } else if (e == 3) {
          data = res.data.itemslists.data;
          if (data.length > 0) {
            data.forEach((v, index) => {
              this.list[e].list.push({
                id: v.id,
                name: v.items_name,
                Latitude: v.Latitude,
                Longitude: v.Longitude,
              });
            });
          }
        } else if (e == 4) {
          data = res.data.buildlists.data;
          if (data.length > 0) {
            data.forEach((v, index) => {
              this.list[e].list.push({
                id: v.id,
                name: v.build_name,
                Latitude: v.Latitude,
                Longitude: v.Longitude,
              });
            });
          }
        } else if (e == 5) {
          data = res.data.masslists.data;
          if (data.length > 0) {
            data.forEach((v, index) => {
              this.list[e].list.push({
                id: v.id,
                name: v.mass_name,
                Latitude: v.Latitude,
                Longitude: v.Longitude,
              });
            });
          }
        } else if (e == 6) {
          data = res.data.famousen.data;
          if (data.length > 0) {
            data.forEach((v, index) => {
              this.list[e].list.push({
                id: v.id,
                name: v.enterprises_name,
              });
            });
          }
        } else if (e == 7) {
          data = res.data.business.data;
          if (data.length > 0) {
            data.forEach((v, index) => {
              this.list[e].list.push({
                id: v.id,
                name: v.business_name,
              });
            });
          }
        }
        this.list[this.navIndex].flag = true;
        // 数据全部加载完成
        if (data.length == 0) {
          this.finished = true;
        }
        // 加载状态结束
        this.loading = false;
        this.list[this.navIndex].page++;
      });
    },
    //动态列表接口
    loding() {
      newsLists({
        region_code: this.$route.params.code,
        belong_cate: this.navIndex + 1,
        page: this.list[this.navIndex].page,
      }).then((res) => {
        let arr = res.data.Newsinfo.data;
        if (arr.length > 0) {
          arr.forEach((v, index) => {
            this.list[this.navIndex].list.push(v);
          });
        }
        // 数据全部加载完成
        if (arr.length == 0) {
          this.finished = true;
          this.list[this.navIndex].flag = true;
        }
        // 加载状态结束
        this.loading = false;
        this.list[this.navIndex].page++;
      });
    },
    onLoad() {
      // 异步更新数据
      if (this.type == 1) {
        this.loding();
      } else if (this.type == 2) {
        this.prokectList();
      } else if (this.type == 6) {
        // 招商政策
        if (this.navList.length == 0) {
          servervice({
            region_code: this.$route.params.code,
            ser_type: 1,
            page: 1,
          }).then((res) => {
            let arr = res.data.find_servervices.data;
            if (arr.length > 0) {
              this.navList = res.data.find_servervices.data;
              this.navList.forEach((v, index) => {
                this.list.push({
                  page: 1,
                  flag: false,
                  list: [],
                });
              });
            }
            this.touFLList(this.navList[this.navIndex].id);
          });
        } else {
          this.touFLList(this.navList[this.navIndex].id);
        }
      }
    },
    //去手册
    tobook(e) {
      window.open(e.e_url);
    },
    //去新闻详情页
    newsDetail(item) {
      let type = item.news_type;
      this.$router.push({
        name: "NewsDetail",
        params: {
          id: item.id,
          type: type,
        },
      });
    },
    //去图集详情
    topic(e) {
      this.$router.push({
        name: "PicDetail",
        params: {
          id: e.id,
          code: this.$route.params.code,
          titles: e.name,
        },
      });
    },
    //去招商优势详情页
    toFour(e) {
      this.$router.push({
        name: "TwDetail",
        params: {
          type: 1,
          cate: e.impression_cate,
          code: this.$route.params.code,
        },
      });
    },
    //去政策详情页
    topolicyDetail(item) {
      this.$router.push({
        name: "TwDetail",
        params: {
          code: this.$route.params.code,
          cate: item.id,
          type: 2,
        },
      });
    },
    //进入项目列表详情
    projectDetail(e) {
      console.log(e);
      let arr = [
        "Park",
        "Massif",
        "Workshop",
        "Project",
        "Building",
        "PopularEn",
        "FamousEnterprises",
        "BusinessAssociation",
      ];
      this.$router.push({
        name: arr[this.navIndex],
        params: {
          code: this.$route.params.code,
          id: e.id,
        },
      });
    },
  },
};
</script>
<style lang='less' scoped>
ul {
  padding: 10px 0;
  li {
    margin-left: 1rem;
    list-style: disc;
    line-height: 44px;
    font-size: 1rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-all;
    cursor: pointer;
  }
}
.fm {
  padding: 10px;
}
.detail {
  padding: 20px;
}
.youshi {
  display: flex;
  align-items: center;
  height: calc(100vh - 44px);
}
.box {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 10px;
  justify-content: space-between;
}
.list {
  width: 49%;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 12px;
  .imgs {
    width: 100%;
    height: 250px;
  }
  .txt {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.5);
  }
}
</style>