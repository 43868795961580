<template>
  <div class="content">
    <div class="box" v-if="picFlag">
      <div
        class="list"
        :style="'height:' + picHeight"
        @click="toDetail(item, index)"
        v-for="(item, index) in list"
        :key="item.id"
      >
        <div class="baom" v-if="baimin">正在报名</div>
        <van-image class="imgs" fit="cover" :src="item.url" />
        <img
          v-if="viodeImg"
          class="bofang"
          src="http://yuyok.oss-cn-hangzhou.aliyuncs.com/storage/storage1/image/icon/bofang.png"
        >
        <div class="title" :class="picPositionFlag ? 'pic_position' : ''">
          <span class="text">{{ item.name }}</span>
        </div>
      </div>
    </div>
    <div class="box" v-else>
      <div
        class="textlist"
        @click="toDetail(item, index)"
        v-for="(item, index) in list"
        :key="item.id"
      >
        <div class="title">
          <span class="text">{{ item.name }}</span>
        </div>
        <span class="time">{{ item.created_at.split(" ")[0] }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { Image as VanImage } from "vant";
export default {
  name: "piclist",
  components: {
    [VanImage.name]: VanImage,
  },
  props: {
    //列表
    list: {
      type: Array,
      default() {
        return [];
      },
    },
    //是否开启图文列表(不开启则是文字列表)
    picFlag: {
      type: Boolean,
      default() {
        return false;
      },
    },
    baimin: {
      type: Boolean,
      default() {
        return false;
      },
    },
    //图片列表采用哪种布局方式(文字放图片上或放图片下面)
    picPositionFlag: {
      type: Boolean,
      default() {
        return true;
      },
    },
    //是否开启播放视频图标
    viodeImg: {
      type: Boolean,
      default() {
        return false;
      },
    },
    //列表的高度
    picHeight: {
      type: String,
      default() {
        return "300px";
      },
    },
  },
  data() {
    return {};
  },
  methods: {
    toDetail(e, i) {
      this.$emit("toDetail", e, i);
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  .bofang {
    width: 60px;
    height: 60px;
    position: absolute;
    left: 50%;
    top: 35%;
    transform: translateX(-50%);
  }
  .baom {
    position: absolute;
    right: 10px;
    bottom: 30px;
    color: #f0ad4e;
  }
  .box {
    .list {
      display: flex;
      flex-direction: column;
      margin: 20px;
      border-radius: 20px;
      box-shadow: 0 0 10px rgba(97, 97, 97, 0.2);
      box-sizing: border-box;
      overflow: hidden;
      position: relative;

      .imgs {
        width: 100%;
		height: 80%;
      }

      .title {
        .text {
          margin: 20px;
          height: 50px;
          font-size: 1.2rem;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          line-clamp: 1;
          -webkit-box-orient: vertical;
        }
      }

      .pic_position {
        position: absolute;
        background-color: rgba(0, 0, 0, 0.5);
        left: 0;
        right: 0;
        bottom: 0;
        overflow: hidden;
        padding: 30px 20px;

        .text {
          color: #fff;
          margin: 0;
          height: auto;
        }
      }
    }

    .textlist {
      margin: 20px;
      padding: 12px 16px;
      height: 160px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      box-sizing: border-box;
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);

      .title {
        letter-spacing: 2px;
        font-size: 1rem;
		color: #333;
        .text {
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }

      .time {
        display: flex;
        justify-content: flex-end;
        font-size: 24px;
        margin-top: 6px;
        color: #666;
      }
    }
  }
}
</style>
